import React from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { usePDF } from 'react-to-pdf';
import PointListTable from "./PointListTable";

function RecievedInvoiceViewer({ item, handleInvoiceClose }) {
    /// Page for viewing any invoices sent to customer or from botmark /// 
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const userData = useSelector((state) => state.UserReducer.user);
    const dispatch = useDispatch();

    
    let data = [];
    let invoiceTotal = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    let pointTypeTotal = 0;
    console.log(pointTypeTotal)

    if (item?.invoiceValues != undefined) {
        data = JSON?.parse(item?.invoiceValues)
        
        for (let i = 0; i < data.items?.length; i++) {
            invoiceTotal += data.items[i].total;
        }
        console.log('total', invoiceTotal);
        for (let i = 0; i < data.taxes?.length; i++) {
            taxTotal += Number(data.taxes[i].total);
        }
        finalTotal = taxTotal + invoiceTotal

        pointTypeTotal = data.pointTypeTotal;

    };

    let pointList = [];
    if (item?.pointList != undefined) {
        pointList = JSON.parse(item?.pointList);
    };

    console.log("ITEM", item);
    console.log("DATA", data);

    /// For File name selection in invoice ///
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    }

    /// For Creating and Downloading Invoice as CSV ///
    function exportToCsv() {
        let csvDownloadable = [
            [`${data.date}`, `${item.developerName}: ${item.fileName.split('.')[0]}`, `${finalTotal}`]
        ];
        let csvContent = "data:text/csv;charset=utf-8,"
            + csvDownloadable.map(e => e.join(",")).join("\n");

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, `${item.developerName.replace(' ', '_')}-${item.fileName.split('.')[0]}`);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", `${item.developerName.replace(' ', '_')}-${item.fileName.split('.')[0]}`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    console.log(item)

    /// For Creating and Downloading Invoice as PDF ///
    const { toPDF, targetRef } = usePDF({ filename: `${item?.developerName?.replace(' ', '_')}-${item?.fileName?.split('.')[0]}.pdf` });

    return (
        <Container id="invoice" className='estimatePage' maxWidth="sm">
            <Container ref={targetRef}>
                <Typography variant="h4" align="center">
                    Invoice
                </Typography>
                <Table>
                    <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                        <span style={{ marginRight: 50 }}>Date: {data.date}</span>
                        <span># {data.estimateNumber}</span>
                    </p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, margin: 2 }}>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>From:</h4>
                                    { data?.customerInfo ?
                                    <div>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.name}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.street}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.city}, {data?.processorInfo?.state}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.zip}, {data?.processorInfo?.country}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.email}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.processorInfo?.phoneNumber}</span></span>
                                    </div>
                                        :
                                    <div>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.name}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.street}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.city}, {data?.botmarkInfo?.state}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.zip}, {data?.botmarkInfo?.country}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.email}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{data?.botmarkInfo?.phoneNumber}</span></span>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div style={{ flex: 1, margin: 2 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                    <h4 style={{ display: "flex", justifyContent: 'left' }}>To:</h4>
                                    {data?.customerInfo ?
                                        <div>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {data?.customerInfo?.userCompany}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {data?.customerInfo?.customerName}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Email: {data?.customerInfo?.userEmail}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Credit Status</span></span>
                                            {data?.customerInfo?.requestStatus === 0 &&
                                                <span style={{color:'purple', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                            }
                                            {data?.customerInfo?.requestStatus === 1 &&
                                                <span style={{color:'green', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                            }
                                            {data?.customerInfo?.requestStatus === 2 &&
                                                <span style={{color:'darkOrange', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                            }
                                            {data?.customerInfo?.requestStatus === 3 &&
                                                <span style={{color:'red', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                            }
                                            <br />
                                        </div>
                                    :
                                        <div>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {data?.processorInfo?.name}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {data?.processorInfo?.processorName}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Email: {data?.processorInfo?.email}</span></span>
                                            <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Credit Status</span></span>
                                            {data?.processorInfo?.companyStatus === 0 &&
                                                <span style={{color:'purple', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Pending</span></span>
                                            }
                                            {data?.processorInfo?.companyStatus === 1 &&
                                                <span style={{color:'green', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with Credit</span></span>
                                            }
                                            {data?.processorInfo?.companyStatus === 2 &&
                                                <span style={{color:'darkOrange', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Approved with out Credit</span></span>
                                            }
                                            {data?.processorInfo?.companyStatus === 3 &&
                                                <span style={{color:'red', display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Denied</span></span>
                                            }
                                            <br />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, margin: 2 }}>
                        </div>
                        <PointListTable pointList={pointList} name={item.fileName} id={item.id} pointTypeTotal={pointTypeTotal}/>
                    </div>
                </Table>
                <br />
                <Typography variant="body1">
                    <b>Invoice</b>
                </Typography>
                <br />
                <Table sx={{ border: "solid" }}>
                    <TableHead>
                        <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>U/M</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody>
                        {data?.items?.map((item, i) => (
                            <TableRow>
                                <TableCell>{item?.itemName}</TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell>{item?.quantity}</TableCell>
                                <TableCell>{item?.unit}</TableCell>
                                <TableCell>{item?.rate}</TableCell>
                                <TableCell> 
                                    <CurrencyFormat 
                                        value={item?.total}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    >
                                        {item.total}
                                    </CurrencyFormat>
                                </TableCell>
                            </TableRow>
                        ))}
                        <br />
                        <br />
                        <br />
                        <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>

                            <TableCell><b>Sub Total:</b></TableCell>
                            <TableCell><CurrencyFormat value={invoiceTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {data?.taxes?.map((item, i) => (
                            <TableRow>
                                <TableCell>{item?.itemName}</TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item?.unit}</TableCell>
                                <TableCell>{item?.rate}</TableCell>
                                <TableCell><CurrencyFormat value={item?.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><b>Total:</b></TableCell>
                            <TableCell><CurrencyFormat value={finalTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br />
            </Container>
            <br />
            <Button onClick={() => exportToCsv()}>Download Invoice as .CSV</Button>
            <Button id="cmd" onClick={() => toPDF()}>Download Invoice as .PDF</Button>
        </Container>
    )
}

export default RecievedInvoiceViewer;