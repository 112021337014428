import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    colors,
    Badge,
    Tabs,
    Tab
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../src/components/Logo';
import { useSelector } from 'react-redux';

const TopBar = ({ onMobileNavOpen, value, handleTabChange }) => {
    const [notifications] = useState([{}, {}]);
    const auth = useSelector((state) => state.UserReducer.isAuthenticated);

    // const [value, setValue] = React.useState(0);
    // const handleTabChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    function notif() {
        if (auth) {
            return (
                <IconButton color="inherit" size="large">
                    <Badge
                        badgeContent={notifications.length}
                        color="warning"
                        variant="standard"
                    >
                        <Notifications color="white" />
                    </Badge>
                </IconButton>
            )
        }
    }

    return (
        <AppBar
            elevation={0}
            sx={{ backgroundColor: colors.grey[900] }}
        >
            <Toolbar>
                <RouterLink to="/" style={{display: 'flex'}}>
                    <Logo />
                </RouterLink>
                <Box flexGrow={1} />
                <Tabs value={value} onChange={handleTabChange} centered style={{borderBottom:'2px solid black', paddingBottom:'10px'}}>
                    <Tab label="Home" style={{color:"rgba(167,11,32)", fontSize:"large", fontWeight:"bold"}}/>
                    <Tab label="Tutorials" style={{color:"rgba(111,0,42)", fontSize:"large", fontWeight:"bold"}}/>
                    <Tab label="About" style={{color:"rgba(211,31,54)", fontSize:"large", fontWeight:"bold"}}/>
                    <Tab label="Contact" style={{color:"rgba(29,128,177)", fontSize:"large", fontWeight:"bold"}}/>
                    { !auth ?
                        <Tab label="Login" style={{color:"rgba(252,120,55)", fontSize:"large", fontWeight:"bold"}}/>
                    :
                        <Tab label="Portal" style={{color:"rgba(252,120,55)", fontSize:"large", fontWeight:"bold"}}/>
                    }
                </Tabs>
                {/* {notif()} */}
                {/* <Hidden lgUp>
                    <IconButton sx={{}} color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden> */}
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;